<template>
  <div
    id="contents"
    :class="{
      plc_monitor: tabIndex != 6,
      plc_th_status: tabIndex == 0,
      crusher: tabIndex == 2,
      plc_package_log: tabIndex == 1 || tabIndex == 3,
      plc_auto_package: tabIndex == 4,
      plc_metal_status: tabIndex == 5,
      default_setting: tabIndex == 6,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="asideIndex.filter(x => x.name != 'all_status')"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(aside, index) in asideIndex"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a @click="SetOpenTabIndex(index)">{{ aside.title }}</a>
          </li>
        </ul>
      </div>
      <TempRealtimeForm v-if="tabIndex == 0" />
      <SoakingForm v-if="tabIndex == 1" />
      <CrusherForm v-if="tabIndex == 2" />
      <SteamMachineForm v-if="tabIndex == 3" />
      <PlcPackageLogForm v-if="tabIndex == 4" />
      <CounterRealtimeForm v-if="tabIndex == 5" />
      <DefaultPlcForm v-if="tabIndex == 6" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import TempRealtimeForm from '@/views/forms/Custom/Monitor/22/TempRealtimeForm';
import SoakingForm from '@/views/forms/Custom/Monitor/22/SoakingForm';
import CrusherForm from '@/views/forms/Custom/Monitor/22/CrusherForm';
import SteamMachineForm from '@/views/forms/Custom/Monitor/22/SteamMachineForm';
import PlcPackageLogForm from '@/views/forms/Custom/Monitor/22/PlcPackageLogForm';
import CounterRealtimeForm from '@/views/forms/Custom/Monitor/22/CounterRealtimeForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/22/DefaultPlcForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    TempRealtimeForm,
    SoakingForm,
    CrusherForm,
    SteamMachineForm,
    PlcPackageLogForm,
    CounterRealtimeForm,
    DefaultPlcForm,
  },
  data() {
    return {
      asideIndex: [
        { title: '실시간 온습도 현황', name: 'real_time_status' },
        { title: '실시간 불림기 현황', name: 'soaking' },
        { title: '실시간 분쇄기 현황', name: 'crusher' },
        { title: '실시간 스팀기 현황', name: 'steam_machine' },
        { title: '실시간 자동포장기 현황', name: 'plc_package_log' },
        { title: '실시간 금속검출기 현황', name: 'plc_auto_package' },
        { title: '기준값 설정', name: 'default_setting' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
    if (this.$route.meta.tab != undefined) {
      this.$store.commit(
        'setOpenTabIndexToPlcMonitorPage',
        this.$route.meta.tab,
      );
      delete this.$route.meta.tab;
    }
  },
};
</script>

<style lang="scss">
@import 'tteok_monitoring';
</style>
