<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>실시간 스팀기 현황</h2>

          <span v-if="plc_sensor_log_now.length > 0"
            >조회시간 :
            {{ plc_sensor_log_now[0].create_time | formatDateHHMMSS }}</span
          ><span v-else>조회시간 : NaN</span>
        </div>
        <div class="monitor_body">
          <div
            class="monitor_card"
            v-for="item in plc_sensor_log_now"
            :key="item.id"
          >
            <h3>
              {{
                item.plc_sensor_type_id == 6
                  ? `${item.plc_sensor_name.substring(5, 8)} - ${
                      item.plc_sensor_name.split('-')[1]
                    }`
                  : `${item.plc_sensor_name.substring(5, 10)}-${
                      item.plc_sensor_name.split('-')[1]
                    }`
              }}
            </h3>
            <p>
              {{ `${item.hour}시 ${item.min}분 ${item.sec}초` }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      counter_interval: null,
      timer: 1,
      fullScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      products: 'getProduct',
      plc_sensor_log_now_src: 'getPlcSensorLog',
      plc_sensors: 'getPlcSensor',
    }),
    plc_sensor_log_now() {
      if (this.plc_sensor_log_now_src.length < 1) {
        return [];
      } else {
        let seconds = this.lodash
          .clonedeep(this.plc_sensor_log_now_src)
          .map(x => ({
            ...x,
            hour: parseInt(this.$decimalDiv(x.value, 3600)),
            min: parseInt(
              this.$decimalDiv(this.$decimalMod(x.value, 3600), 60),
            ),
            sec: this.$decimalMod(x.value, 60),
          }));
        return seconds
          .filter(x => [6, 7].includes(x.plc_sensor_type_id))
          .sort(
            (a, b) =>
              b.plc_sensor_detail_type_id - a.plc_sensor_detail_type_id ||
              a.plc_sensor_id - b.plc_sensor_id,
          );
      }
    },
  },
  destroyed() {
    clearInterval(this.counter_interval);
    this.counter_interval = null;
  },
  watch: {
    async timer(newValue) {
      if (newValue == 0 && this.tabIndex == 2) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '스팀기');
        this.timer = 10;
      }
    },
  },
  async created() {
    await this.FETCH('FETCH_PLC_SENSOR_LOG_NOW', '스팀기');
    if (this.counter_interval == null) {
      this.counter_interval = setInterval(() => {
        this.timer--;
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped></style>
